<template>
    <div>
        <HRetribusi />
        <panel
            title="FORM REGISTRASI WAJIB RETRIBUSI DAERAH"
            class="panel panel-success"
        >
            <div class="my-3">
                <b-card>
                    <b-button variant="primary" :to="{name: 'ListNPWRD'}">
                        <i class="fa fa-arrow-circle-left"></i> Kembali
                    </b-button>
                </b-card>
            </div>
            <div class="nav-wizards-container">
                <nav class="nav nav-wizards-1 mb-2">
                    <!-- completed -->
                    <div class="nav-item col">
                        <b-nav-item
                            :class="{
                                'nav-link': true,
                                active: activeVar.isActive1,
                                completed: completeVar.isComplete1,
                                disabled: Pendaftaran.isDisable
                            }"
                        >
                            <div class="nav-no">1</div>
                            <div class="nav-text font-weight-bold">
                                WAJIB RETRIBUSI DAERAH
                            </div>
                        </b-nav-item>
                    </div>

                    <!-- active -->
                    <div class="nav-item col">
                        <router-link
                            to="/pendaftaran/pimpinan"
                            v-slot="{ href }"
                        >
                            <a
                                :class="{
                                    'nav-link': true,
                                    active: activeVar.isActive2,
                                    completed: completeVar.isComplete2,
                                    disabled: Pendaftaran.isDisable2,
                                }"
                                :key="href"
                                style="cursor: pointer;"
                            >
                                <div class="nav-no">2</div>
                                <div class="nav-text font-weight-bold">
                                    PIMPINAN
                                </div>
                            </a>
                        </router-link>
                    </div>

                    <!-- disabled -->
                    <div class="nav-item col">
                        <router-link
                            to="/pendaftaran/penanggung-jawab"
                            v-slot="{ href }"
                        >
                            <a
                                :class="{
                                    'nav-link': true,
                                    active: activeVar.isActive3,
                                    completed: completeVar.isComplete3,
                                    disabled: Pendaftaran.isDisable3,
                                }"
                                :key="href"
                                style="cursor: pointer;"
                            >
                                <div class="nav-no">3</div>
                                <div class="nav-text font-weight-bold">
                                    PENANGGUNG JAWAB
                                </div>
                            </a>
                        </router-link>
                    </div>
                </nav>
            </div>

            <div class="card">
                <div class="card-body">
                    <router-view />
                </div>
            </div>
        </panel>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "@/components/npwr/HRetribusi.vue";
import { mapState } from "vuex";

export default {
    components: {
        HRetribusi,
    },
    data() {
        return {
            activeVar: {
                isActive1: false,
                isActive2: false,
                isActive3: false,
            },
            completeVar: {
                isComplete1: false,
                isComplete2: false,
                isComplete3: false,
            },
        };
    },
    created() {
        this.stepClass();
    },
    computed: {
        ...mapState(["Pendaftaran"]),
    },
    watch: {
        $route(to, from) {
            this.stepClass();
        },
    },
    methods: {
        stepClass() {
            switch (this.$route.name) {
                case "EditNpwrd":
                case "DaftarNpwrd":
                    // jika step 1 aktif
                    this.activeVar.isActive1 = true;
                    this.activeVar.isActive2 = false;
                    this.activeVar.isActive3 = false;
                    // jika step 1 complete
                    this.completeVar.isComplete1 = true;
                    this.completeVar.isComplete2 = false;
                    this.completeVar.isComplete3 = false;
                    break;
                case "EditPimpinan":
                case "DaftarPimpinan":
                    this.activeVar.isActive1 = false;
                    this.activeVar.isActive2 = true;
                    this.activeVar.isActive3 = false;

                    // jika step 2 complete
                    this.completeVar.isComplete1 = true;
                    this.completeVar.isComplete2 = false;
                    this.completeVar.isComplete3 = false;
                    break;
                case "EditPenanggungJawab":
                case "DaftarPenanggungJawab":
                    this.activeVar.isActive1 = false;
                    this.activeVar.isActive2 = false;
                    this.activeVar.isActive3 = true;

                    // jika step 2 complete
                    this.completeVar.isComplete1 = true;
                    this.completeVar.isComplete2 = true;
                    this.completeVar.isComplete3 = false;
                    break;
                default:
                    this.activeVar.isActive1 = false;
                    this.activeVar.isActive2 = false;
                    this.activeVar.isActive3 = false;

                    // jika step 1 complete
                    this.completeVar.isComplete1 = false;
                    this.completeVar.isComplete2 = false;
                    this.completeVar.isComplete3 = false;
            }
        },
    },
    beforeRouteLeave (to, from, next) {
        localStorage.setItem('editMode', false)
        this.$store.dispatch("Pendaftaran/isEventDisable2", false)
        this.$store.dispatch("Pendaftaran/isEventDisable3", false)
        next()
    }
};
</script>
