var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('HRetribusi'),_c('panel',{staticClass:"panel panel-success",attrs:{"title":"FORM REGISTRASI WAJIB RETRIBUSI DAERAH"}},[_c('div',{staticClass:"my-3"},[_c('b-card',[_c('b-button',{attrs:{"variant":"primary","to":{name: 'ListNPWRD'}}},[_c('i',{staticClass:"fa fa-arrow-circle-left"}),_vm._v(" Kembali ")])],1)],1),_c('div',{staticClass:"nav-wizards-container"},[_c('nav',{staticClass:"nav nav-wizards-1 mb-2"},[_c('div',{staticClass:"nav-item col"},[_c('b-nav-item',{class:{
                            'nav-link': true,
                            active: _vm.activeVar.isActive1,
                            completed: _vm.completeVar.isComplete1,
                            disabled: _vm.Pendaftaran.isDisable
                        }},[_c('div',{staticClass:"nav-no"},[_vm._v("1")]),_c('div',{staticClass:"nav-text font-weight-bold"},[_vm._v(" WAJIB RETRIBUSI DAERAH ")])])],1),_c('div',{staticClass:"nav-item col"},[_c('router-link',{attrs:{"to":"/pendaftaran/pimpinan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var href = ref.href;
return [_c('a',{key:href,class:{
                                'nav-link': true,
                                active: _vm.activeVar.isActive2,
                                completed: _vm.completeVar.isComplete2,
                                disabled: _vm.Pendaftaran.isDisable2,
                            },staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"nav-no"},[_vm._v("2")]),_c('div',{staticClass:"nav-text font-weight-bold"},[_vm._v(" PIMPINAN ")])])]}}])})],1),_c('div',{staticClass:"nav-item col"},[_c('router-link',{attrs:{"to":"/pendaftaran/penanggung-jawab"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var href = ref.href;
return [_c('a',{key:href,class:{
                                'nav-link': true,
                                active: _vm.activeVar.isActive3,
                                completed: _vm.completeVar.isComplete3,
                                disabled: _vm.Pendaftaran.isDisable3,
                            },staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"nav-no"},[_vm._v("3")]),_c('div',{staticClass:"nav-text font-weight-bold"},[_vm._v(" PENANGGUNG JAWAB ")])])]}}])})],1)])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('router-view')],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }